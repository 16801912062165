import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
    static targets = ["todayBadge", "upcomingBadge"]
    static values = {
        tabType: String,
        initialToday: Number,
        initialUpcoming: Number
    }

    connect() {
        this.animateUpdate(this.todayBadgeTarget, this.initialTodayValue)
        this.animateUpdate(this.upcomingBadgeTarget, this.initialUpcomingValue)

        this.channel = consumer.subscriptions.create("BadgeUpdatesChannel", {
            received: (data) => {
                const tabData = data[this.tabTypeValue]
                if (tabData) {
                    this.animateUpdate(this.todayBadgeTarget, tabData.today)
                    this.animateUpdate(this.upcomingBadgeTarget, tabData.upcoming)
                }
            }
        })
    }

    disconnect() {
        if (this.channel) {
            this.channel.unsubscribe()
        }
    }

    animateUpdate(badgeElement, newCount) {
        const oldCount = parseInt(badgeElement.textContent)
        if (oldCount !== newCount) {
            // Remove existing animation class
            badgeElement.classList.remove('badge-update')

            // Force reflow
            void badgeElement.offsetWidth

            // Update the count
            badgeElement.textContent = newCount

            if (newCount === 0) {
                badgeElement.classList.add('hidden')
            } else {
                badgeElement.classList.remove('hidden')
                badgeElement.classList.add('badge-update')

                // Remove the class after animation completes
                setTimeout(() => {
                    badgeElement.classList.remove('badge-update')
                }, 400) // Match this to your CSS animation duration
            }
        }
    }
}